export const SLIDE_VISIBLE = 'visible';
export const SLIDE_HIDDEN = 'hidden';

export type SlideVisibility = typeof SLIDE_VISIBLE | typeof SLIDE_HIDDEN;

export const RESPONSES_VISIBLE = 'visible';
export const RESPONSES_HIDDEN = 'hidden';

export type ResponseVisibility =
  | typeof RESPONSES_VISIBLE
  | typeof RESPONSES_HIDDEN;

export const INSTRUCTIONS_BAR_HIDDEN = 'hidden';
export const INSTRUCTIONS_BAR_VISIBLE = 'visible';

export type InstructionsBarVisibility =
  | typeof INSTRUCTIONS_BAR_HIDDEN
  | typeof INSTRUCTIONS_BAR_VISIBLE;

export const QR_CODE_HIDDEN = 'hidden';
export const QR_CODE_VISIBLE = 'visible';

export type QrCodeVisibility = typeof QR_CODE_HIDDEN | typeof QR_CODE_VISIBLE;

export const QA_QUESTION_VISIBLE_PUBLIC = 'public';
export const QA_QUESTION_VISIBLE_MODERATORS_ONLY = 'moderators-only';

export type QaQuestionVisibility =
  | typeof QA_QUESTION_VISIBLE_PUBLIC
  | typeof QA_QUESTION_VISIBLE_MODERATORS_ONLY;

export const QA_ENABLED_ALL_SLIDES = 'all-slides';
export const QA_ENABLED_QA_SLIDES_ONLY = 'qa-slides-only';

export type QaEnablementScope =
  | typeof QA_ENABLED_ALL_SLIDES
  | typeof QA_ENABLED_QA_SLIDES_ONLY;

export const QA_MODERATED = 'moderated';
export const QA_NOT_MODERATED = 'not-moderated';

export type QaModerationPolicy = typeof QA_MODERATED | typeof QA_NOT_MODERATED;

export const VOTE_ON_RESPONSE_ALLOW = 'allow-votes';
export const VOTE_ON_RESPONSE_DENY = 'deny-votes';

export const INTERACTIVE_CONTENT_ACCEPTING_RESPONSES = 'accepting-responses';
export const INTERACTIVE_CONTENT_RESPONSES_BLOCKED = 'responses-blocked';

export type InteractiveContentResponseMode =
  | typeof INTERACTIVE_CONTENT_ACCEPTING_RESPONSES
  | typeof INTERACTIVE_CONTENT_RESPONSES_BLOCKED;

export const SCORING_TIME_BASED_TYPE = 'time_based';

export const LIVE_CHAT_ENABLED = 'enabled';
export const LIVE_CHAT_DISABLED = 'disabled';

export type LiveChatStatus =
  | typeof LIVE_CHAT_ENABLED
  | typeof LIVE_CHAT_DISABLED;

export const RESPONSE_POLICY_NO_RESTRICTION = 'no-restriction';

// need to send in just one choice when voting
export const RESPONSE_POLICY_SINGLE_RESPONSE_SINGLE_CHOICE =
  'single-response-single-choice';

// need to send in specific amount of choices when voting
export const RESPONSE_POLICY_SINGLE_RESPONSE_LIMITED_CHOICES =
  'single-response-limited-choices';

// need to send in all available choices when voting
export const RESPONSE_POLICY_SINGLE_RESPONSE_ALL_CHOICES =
  'single-response-all-choices';

// no restriction how many choices to send in when voting
export const RESPONSE_POLICY_SINGLE_RESPONSE_NO_CHOICE_RESTRICTION =
  'single-response-no-choice-restriction';

export const MULTIPLE_VOTE_POLICIES = [
  RESPONSE_POLICY_NO_RESTRICTION,
  RESPONSE_POLICY_SINGLE_RESPONSE_LIMITED_CHOICES,
];
export const CORRECT_AREA_ENABLED = 'enabled';
export const CORRECT_AREA_DISABLED = 'disabled';

export type CorrectAreaEnabledStatus =
  | typeof CORRECT_AREA_ENABLED
  | typeof CORRECT_AREA_DISABLED;

export const PARTICIPATION_MODE_SURVEY = 'survey';
export const PARTICIPATION_MODE_PRESENTATION = 'presentation';

export type ParticipationMode =
  | typeof PARTICIPATION_MODE_SURVEY
  | typeof PARTICIPATION_MODE_PRESENTATION;

export const PARTICIPATION_POLICY_NO_RESTRICTION = 'no-restriction';
export const PARTICIPATION_POLICY_SINGLE_SUBMISSION = 'single-submission';

export type ParticipationPolicy =
  | typeof PARTICIPATION_POLICY_NO_RESTRICTION
  | typeof PARTICIPATION_POLICY_SINGLE_SUBMISSION;

export const COLLABORATION_MODE_COLLABORATIVE = 'collaborative';
export const COLLABORATION_MODE_NON_COLLABORATIVE = 'non-collaborative';

export type CollaborationMode =
  | typeof COLLABORATION_MODE_COLLABORATIVE
  | typeof COLLABORATION_MODE_NON_COLLABORATIVE;

export const SLIDE_DECK_PARTICIPATION_RESPONSE_MODE_ACCEPTING_RESPONSES =
  'accepting-responses';
export const SLIDE_DECK_PARTICIPATION_RESPONSE_MODE_RESPONSES_BLOCKED =
  'responses-blocked';

export type ParticipationResponseMode =
  | typeof SLIDE_DECK_PARTICIPATION_RESPONSE_MODE_ACCEPTING_RESPONSES
  | typeof SLIDE_DECK_PARTICIPATION_RESPONSE_MODE_RESPONSES_BLOCKED;

export const CORRECT_ANSWER_MODE_ENABLED = 'enabled';
export const CORRECT_ANSWER_MODE_DISABLED = 'disabled';

export type CorrectAnswerMode =
  | typeof CORRECT_ANSWER_MODE_ENABLED
  | typeof CORRECT_ANSWER_MODE_DISABLED;

export const INTERACTIVE_CONTENT_RESPONSE_COUNT_DISPLAY_MODE_ABSOLUTE =
  'absolute';
export const INTERACTIVE_CONTENT_RESPONSE_COUNT_DISPLAY_MODE_PERCENTAGE =
  'percentage';
export type InteractiveContentResponseCountValueDisplayMode =
  | typeof INTERACTIVE_CONTENT_RESPONSE_COUNT_DISPLAY_MODE_ABSOLUTE
  | typeof INTERACTIVE_CONTENT_RESPONSE_COUNT_DISPLAY_MODE_PERCENTAGE;
