import type { Question, QuestionChoice } from '@mentimeter/http-clients';
import type { Image } from '@core-api/editor/types/response';

const questionImageToSlideImage = (question: Question): Image | undefined => {
  if (question.image && question.question_image_url) {
    return {
      imageId: question.image.id,
      altText: question.image.alt_description ?? '',
      path: (question.image as unknown as { s3_key: string }).s3_key,
      originalHeight: question.image.original_height ?? 0,
      originalWidth: question.image.original_width ?? 0,
      // FIXME remove type cast when api response types are updated
      presets: question.image.presets as Image['presets'],
      crop: question.image.crop
        ? {
            x: question.image.crop.x,
            y: question.image.crop.y,
            width: question.image.crop.width,
            height: question.image.crop.height,
          }
        : {
            x: 0,
            y: 0,
            width: question.image.original_width ?? 0,
            height: question.image.original_height ?? 0,
          },
    };
  }

  return undefined;
};

export const convertLegacyChoiceImage = (
  choice: QuestionChoice,
): Image | null => {
  if (choice.image && choice.image_url) {
    return {
      imageId: choice.image.id,
      altText: choice.image.alt_description ?? '',
      path: choice.image_path ?? '',
      originalHeight: choice.image.original_height ?? 0,
      originalWidth: choice.image.original_width ?? 0,
      presets: choice.image.presets as Image['presets'],
      crop: choice.image.crop
        ? {
            x: choice.image.crop.x,
            y: choice.image.crop.y,
            width: choice.image.crop.width,
            height: choice.image.crop.height,
          }
        : {
            x: 0,
            y: 0,
            width: choice.image.original_width ?? 0,
            height: choice.image.original_height ?? 0,
          },
    };
  }

  return null;
};

export const questionImagesToSlideImages = (
  question: Question | undefined,
): Array<Image> => {
  const images: Array<Image> = [];
  if (!question) {
    return images;
  }

  const image = questionImageToSlideImage(question);
  if (image) {
    images.push(image);
  }
  question.choices?.forEach((choice) => {
    const image = convertLegacyChoiceImage(choice);
    if (image) {
      images.push(image);
    }
  });
  return images;
};
