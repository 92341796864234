import type { InteractiveContent } from '@mentimeter/editor-schema/api-types-overrides';
import type { Question } from '@mentimeter/http-clients';
import type { NumericalQuestionInteractiveContentStylingSchema as InteractiveContentStylingSchema } from '@mentimeter/schema/interactive-content-styling';
import { getOrSetCache, type WithCache } from '../../utils/get-or-set-cache';
import type { CompatibilitySlide } from '../../compatibility-types';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionGuessTheNumberProperty = (
  target: CompatibilitySlide,
  prop: keyof Omit<Question, CommonQuestionProperty>,
) => {
  const interactiveContent = target.interactiveContents?.[0];

  if (interactiveContent) {
    switch (prop) {
      case 'module_custom_data': {
        const moduleCustomData = getModuleCustomData(interactiveContent);
        const cacheKey = JSON.stringify(moduleCustomData);

        return getOrSetCache(
          target as WithCache<CompatibilitySlide>,
          'module_custom_data',
          cacheKey,
          moduleCustomData,
        );
      }
      case 'range': {
        const range = getRange(interactiveContent);
        const cacheKey = JSON.stringify(range);

        return getOrSetCache(
          target as WithCache<CompatibilitySlide>,
          'range',
          cacheKey,
          range,
        );
      }
      default:
        return undefined;
    }
  }
};

const getModuleCustomData = (interactiveContent: InteractiveContent) => {
  const choice = interactiveContent.choices?.[0];
  const range = choice?.correctAnswerRange as { min: number; max: number };

  const errorMargin = Math.ceil((range.max - range.min) / 2);
  const correctAnswer = range?.min + errorMargin;

  return {
    correct_answer: correctAnswer,
    display_min: interactiveContent?.responseRange?.min,
    error_margin: errorMargin,
    has_error_margin: errorMargin > 0,
    scale: (interactiveContent.styling as InteractiveContentStylingSchema)
      .responseRangeScale,
  };
};

const getRange = (interactiveContent: InteractiveContent) => {
  const responseRange = interactiveContent.responseRange;

  if (!responseRange) {
    return undefined;
  }

  const min = responseRange.min || 0;
  const max = responseRange.max || 0;

  const scale = (interactiveContent.styling as InteractiveContentStylingSchema)
    .responseRangeScale;

  return {
    min: 0,
    max: Math.ceil((max - min) / scale),
  };
};
