import {
  QR_CODE_HIDDEN,
  QR_CODE_VISIBLE,
  type QrCodeVisibility,
} from '../../../constants';

export const questionHideJoinInstructionsToQrCodeVisibility = (
  hideJoinInstructions: boolean,
): QrCodeVisibility => {
  return hideJoinInstructions ? QR_CODE_HIDDEN : QR_CODE_VISIBLE;
};
