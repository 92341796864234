import type { Question } from '@mentimeter/http-clients';
import type { QuizStaticContentSchema } from '@mentimeter/schema/static-content';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';

export const getQuizChoicesStaticContent = (question: Question) => {
  const { textSizeOffset } = questionTextLayoutToSlideTextLayout(
    question.layout,
  );
  const content: QuizStaticContentSchema = {
    type: 'quiz-choice',
    meta: question.title_meta,
    image: questionToSlideImageReference(question),
    styling: {
      textSizeOffset,
    },
  };

  return content;
};
