import type { Question, QuestionDimension } from '@mentimeter/http-clients';
import type { ScalesInteractiveContentStylingSchema as Styling } from '@mentimeter/schema/interactive-content-styling';

export const questionDimensionsToSlideScaleResponseRangeLabels = (
  dimensions: Question['dimensions'],
): NonNullable<Styling['responseRangeLabels']> => {
  return Object.entries(dimensions?.[0] ?? []).reduce(
    (acc, [curr, value]) => {
      const key = curr as keyof QuestionDimension;

      switch (key) {
        case 'max':
          acc['maximumValueLabel'] = value;
          break;
        case 'min':
          acc['minimumValueLabel'] = value;
          break;
        case 'mid_values':
          acc['middleValueLabels'] = value ?? [];
          break;
      }

      return acc;
    },
    {
      maximumValueLabel: '',
      minimumValueLabel: '',
      middleValueLabels: [],
    } as NonNullable<Styling['responseRangeLabels']>,
  );
};
