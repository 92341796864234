import type { InteractiveContentWithStyling } from '@mentimeter/editor-schema/api-types-overrides';
import type { Question } from '@mentimeter/http-clients';
import type { CompatibilitySlide } from '../../compatibility-types';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionRankingProperty = (
  target: CompatibilitySlide,
  prop: keyof Question,
) => {
  const interactiveContents = target.interactiveContents?.[0] as
    | InteractiveContentWithStyling<'ranking'>
    | undefined;

  if (interactiveContents) {
    switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
      default:
        return undefined;
    }
  }
};
