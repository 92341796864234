import type { Question } from '@mentimeter/http-clients';
import type { InteractiveContent } from '@mentimeter/editor-schema/api-types-overrides';
import {
  RESPONSE_POLICY_SINGLE_RESPONSE_SINGLE_CHOICE,
  SCORING_TIME_BASED_TYPE,
} from '../../constants';
import { questionActiveToResponseMode } from '../converters/question/question-active-to-response-mode';

export const getQuizChoicesInteractiveContent = (question: Question) => {
  const content: Partial<InteractiveContent> = {
    maxEntries: null,
    scoring: question.time_based_scoring ? SCORING_TIME_BASED_TYPE : null,
    countdown: question.countdown_to_vote,
    interactiveContentId: `${question.id}-interactive`,
    title: question.question,
    description: question.question_description,
    styling: null,
    choices: question.choices.map((choice) => ({
      interactiveContentChoiceId: choice.id.toString(),
      markedCorrect: choice.correct_answer,
      title: choice.label,
      styling: null,
      image: null,
      correctAnswerRange: null,
      legacyChoiceId: null,
      legacyQuestionMetadataFieldId: null,
    })),
    responseMode: questionActiveToResponseMode(question.active),
    responsePolicy: RESPONSE_POLICY_SINGLE_RESPONSE_SINGLE_CHOICE,
  };

  return content;
};
