import type { Question } from '@mentimeter/http-clients';
import type { QuestionsFromAudienceStaticContentSchema as StaticContentSchema } from '@mentimeter/schema/static-content';
import type { CompatibilitySlide } from '../../compatibility-types';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionQuestionsFromAudienceProperty = (
  target: CompatibilitySlide,
  prop: keyof Question,
) => {
  const staticContents = target.staticContent as Extract<
    StaticContentSchema,
    { type: 'questions-from-audience' }
  >;

  switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
    case 'question':
      return staticContents.heading;
    default:
      return undefined;
  }
};
