import type { Question } from '@mentimeter/http-clients';
import type { PowerPointStaticContentSchema } from '@mentimeter/schema/static-content';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';

export const getPowerPointStaticContent = (question: Question) => {
  const content: PowerPointStaticContentSchema = {
    version: 'v1',
    type: 'powerpoint',
    url: question.module_custom_data?.powerpoint_url ?? '',
    startingSlideNumber:
      question.module_custom_data?.starting_slide_number ?? 1,
    styling: {
      version: 'v1',
      ...questionTextLayoutToSlideTextLayout(question.layout),
    },
  };

  return content;
};
