import type { Question } from '@mentimeter/http-clients';

export const isLegacyImageProps = (prop: keyof Question) => {
  return [
    'question_image_url',
    'cropped_image_url',
    'image',
    'image_alt_description',
  ].includes(prop);
};
