import type { Question } from '@mentimeter/http-clients';
import type { HeadingStaticContentSchema } from '@mentimeter/schema/static-content';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';

export const getHeadingStaticContent = (question: Question) => {
  const content: HeadingStaticContentSchema = {
    version: 'v1',
    type: 'heading',
    subheading: question.question_description,
    heading: question.question,
    meta: question.title_meta,
    image: questionToSlideImageReference(question),
    styling: {
      version: 'v1',
      ...questionTextLayoutToSlideTextLayout(question.layout),
    },
  };

  return content;
};
