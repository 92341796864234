import type { InteractiveContent } from '@mentimeter/editor-schema/api-types-overrides';
import type { QuestionChoice } from '@mentimeter/http-clients';
import { getOrSetCache, type WithCache } from '../../../utils/get-or-set-cache';
import type { CompatibilitySlide } from '../../../compatibility-types';
import { choiceToQuestionChoice } from '../../converters/interactive-content';

export function getCachedChoicesProperty(
  slide: CompatibilitySlide,
  interactiveContent: InteractiveContent,
) {
  const choices: Array<QuestionChoice> =
    interactiveContent.choices?.map((c, i) => ({
      ...choiceToQuestionChoice(c),
      id: c.legacyChoiceId ?? i + 1,
      position: i,
    })) ?? [];

  const cacheKey = JSON.stringify(choices);
  return getOrSetCache(
    slide as WithCache<CompatibilitySlide>,
    'choices',
    cacheKey,
    choices,
  );
}
