import type { Question } from '@mentimeter/http-clients';
import type { InteractiveContent } from '@mentimeter/editor-schema/api-types-overrides';
import { questionActiveToResponseMode } from '../converters/question/question-active-to-response-mode';
import {
  RESPONSE_POLICY_NO_RESTRICTION,
  RESPONSE_POLICY_SINGLE_RESPONSE_SINGLE_CHOICE,
} from '../../constants';

export const getWordcloudInteractiveContent = (question: Question) => {
  const content: Partial<InteractiveContent> = {
    maxEntries: null,
    scoring: null,
    countdown: question.countdown_to_vote,
    interactiveContentId: `${question.id}-interactive`,
    title: question.question,
    description: question.question_description,
    styling: {
      type: 'word-cloud',
      answersPerResponse: question.max_nb_words,
    },
    choices: [],
    responseMode: questionActiveToResponseMode(question.active),
    responsePolicy: getResponsePolicy(question),
  };

  return content;
};

export const getResponsePolicy = (question: Question) => {
  if (question.multiple_votes) {
    return RESPONSE_POLICY_NO_RESTRICTION;
  }

  return RESPONSE_POLICY_SINGLE_RESPONSE_SINGLE_CHOICE;
};
