import type { Theme } from '@mentimeter/http-clients';
import type { CompatibilitySlide } from '../../../compatibility-types';
import { imageToQuestionImage } from './image-to-question-image';

type PartialOrUndefined<T> = {
  [P in keyof T]?: T[P] | undefined;
};

export const designToQuestionThemeSettings = (
  design: CompatibilitySlide['design'] | undefined,
): PartialOrUndefined<Theme> => {
  const logoImage = design?.logoImage;
  const colors = design?.colors;
  const computedColors = design?.computedColors;

  const result: PartialOrUndefined<Theme> = {
    background_color: colors?.backgroundColor,
    bar_color: colors?.visualizationColors,
    text_color: colors?.textColor,
    line_color: colors?.lineColor,
    bar_text_color: computedColors?.barTextColors,
    font: design?.font,
    logo: logoImage ? imageToQuestionImage(logoImage) : undefined,
    logo_url: logoImage
      ? (logoImage.presets?.source?.url ?? logoImage.presets?.large?.url)
      : undefined,
    // FIXME: these should mapped from the new model. Will be revised together with backend.
    // background_escaped_path: questionThemeSettings?.background_escaped_path,
    // config_id: questionThemeSettings?.config_id,
    // is_public: questionThemeSettings?.is_public,
    // logo_cropped_image_url: questionThemeSettings?.logo_cropped_image_url,
    // logo_escaped_path: questionThemeSettings?.logo_escaped_path,
    // logo_media_params: questionThemeSettings?.logo_media_params,
  };

  if (colors?.backgroundAlpha) {
    result.backdrop_alpha = colors.backgroundAlpha;
  }

  const backgroundImage = design?.backgroundImage?.image;

  if (backgroundImage) {
    result.background_media_params = {
      natural_height: backgroundImage?.originalHeight,
      natural_width: backgroundImage?.originalWidth,
      height: backgroundImage?.crop.height,
      width: backgroundImage?.crop.width,
      x: backgroundImage?.crop.x,
      y: backgroundImage?.crop.y,
    };

    result.background_url = design.backgroundImage?.custom
      ? backgroundImage?.presets.source.url
      : undefined;

    result.background_cropped_image_url = design.backgroundImage?.custom
      ? (backgroundImage?.presets?.original?.url ??
        backgroundImage?.presets?.large?.url)
      : undefined;
  }

  return result;
};
