import type { Question } from '@mentimeter/http-clients';
import type { RatingStaticContentSchema } from '@mentimeter/schema/static-content';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';

export const getRatingStaticContent = (question: Question) => {
  const { textSizeOffset } = questionTextLayoutToSlideTextLayout(
    question.layout,
  );
  const content: RatingStaticContentSchema = {
    version: 'v1',
    type: 'rating',
    meta: question.title_meta,
    image: questionToSlideImageReference(question),
    styling: {
      version: 'v1',
      textSizeOffset,
    },
  };

  return content;
};
