import type { Question } from '@mentimeter/http-clients';
import type { GoogleSlidesStaticContentSchema } from '@mentimeter/schema/static-content';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';

export const getGoogleSlidesStaticContent = (question: Question) => {
  const content: GoogleSlidesStaticContentSchema = {
    version: 'v1',
    type: 'google-slides',
    url: question.module_custom_data?.google_slides_url ?? '',
    startingSlideNumber:
      question.module_custom_data?.starting_slide_number ?? 1,
    styling: {
      version: 'v1',
      ...questionTextLayoutToSlideTextLayout(question.layout),
    },
  };

  return content;
};
