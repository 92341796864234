import {
  INSTRUCTIONS_BAR_HIDDEN,
  INSTRUCTIONS_BAR_VISIBLE,
  type InstructionsBarVisibility,
} from '../../../constants';

export const questionHideVotingInstructionsBarToInstructionsBarVisibility = (
  hideVotingInstructions: boolean,
): InstructionsBarVisibility => {
  return hideVotingInstructions
    ? INSTRUCTIONS_BAR_HIDDEN
    : INSTRUCTIONS_BAR_VISIBLE;
};
