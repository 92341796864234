import type { Question } from '@mentimeter/http-clients';

export const questionModuleCustomDataToChoicesCorrectAnswerRange = (
  question: Pick<Question, 'module_custom_data'>,
) => {
  const correctAnswer = question.module_custom_data?.correct_answer;

  let margin = 0;

  if (question.module_custom_data?.has_error_margin) {
    margin = question.module_custom_data?.error_margin || 1;
  }

  return {
    min: Math.max(correctAnswer - margin, 0),
    max: correctAnswer + margin,
  };
};
