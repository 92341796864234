import type { Question } from '@mentimeter/http-clients';

const DEFAULT_RESPONSE_RANGE_SCALE = 10;
const DEFAULT_RESPONSE_RANGE_OFFSET = 0;

export const questionModuleCustomDataToSlideResponseRange = (
  question: Pick<Question, 'module_custom_data' | 'range'>,
) => {
  const offset =
    question.module_custom_data?.display_min || DEFAULT_RESPONSE_RANGE_OFFSET;

  const scale =
    question.module_custom_data?.scale || DEFAULT_RESPONSE_RANGE_SCALE;

  return {
    min: question.range.min * scale + offset,
    max: question.range.max * scale + offset,
  };
};
