import {
  INTERACTIVE_CONTENT_ACCEPTING_RESPONSES,
  INTERACTIVE_CONTENT_RESPONSES_BLOCKED,
  type InteractiveContentResponseMode,
} from '../../../constants';

export const questionActiveToResponseMode = (
  active: boolean,
): InteractiveContentResponseMode => {
  return active
    ? INTERACTIVE_CONTENT_ACCEPTING_RESPONSES
    : INTERACTIVE_CONTENT_RESPONSES_BLOCKED;
};
