import type { QuestionType } from '@mentimeter/http-clients';
import type { StaticContentSchema } from '@mentimeter/schema/static-content';

export const QuestionTypeByType: Record<
  StaticContentSchema['type'],
  QuestionType
> = {
  'free-text': 'free-text',
  'google-slides': 'slide',
  'guess-the-number': 'scales',
  'multiple-choice': 'choices',
  'open-ended': 'open',
  'pin-on-image': 'pin_on_image',
  'questions-from-audience': 'qfa',
  'quick-form': 'metadata',
  'quiz-choice': 'quiz',
  'quiz-open': 'quiz_open',
  'word-cloud': 'wordcloud',
  big: 'slide',
  document: 'slide',
  heading: 'slide',
  image: 'slide',
  instructions: 'slide',
  leaderboard: 'quiz_leaderboard',
  list: 'slide',
  miro: 'slide',
  number: 'slide',
  paragraph: 'slide',
  powerpoint: 'slide',
  prioritization: 'prioritisation',
  quote: 'slide',
  ranking: 'ranking',
  rating: 'rating',
  scales: 'scales',
  video: 'slide',
};

export const typeToQuestionType = (
  staticContentType: StaticContentSchema['type'],
) => {
  return QuestionTypeByType[staticContentType];
};
