import type { Question } from '@mentimeter/http-clients';
import type { ImageReferenceSchema } from '@mentimeter/schema/partials';
import { questionLayoutToSlideLayout } from './question-layout-to-slide-layout';

export const questionToSlideImageReference = (
  question: Question,
): ImageReferenceSchema | undefined => {
  const [, layoutPosition, inset] = questionLayoutToSlideLayout(
    question.layout,
  );

  return {
    imagePath: question.image?.s3_key,
    imageLayoutPosition: layoutPosition,
    imageInset: inset,
  };
};
