import type { Question } from '@mentimeter/http-clients';
import type { MiroStaticContentSchema } from '@mentimeter/schema/static-content';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';

export const getMiroStaticContent = (question: Question) => {
  const content: MiroStaticContentSchema = {
    version: 'v1',
    type: 'miro',
    meta: question.title_meta,
    heading: question.question,
    description: question.question_description,
    url: question.module_custom_data?.miro_url ?? '',
    showBoardToAudience: !!question.module_custom_data?.audience_board,
    image: questionToSlideImageReference(question),
    styling: {
      version: 'v1',
      ...questionTextLayoutToSlideTextLayout(question.layout),
    },
  };

  return content;
};
