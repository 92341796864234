import type { Question } from '@mentimeter/http-clients';
import type { ScalesInteractiveContentStylingSchema as Styling } from '@mentimeter/schema/interactive-content-styling';

type ResponseRangeLabels = Styling['responseRangeLabels'];

export const scalesResponseRangeLabelsToQuestionDimensions = (
  labels: ResponseRangeLabels | undefined,
): Question['dimensions'] => {
  const dimension = Object.entries(labels ?? {}).reduce(
    (acc, [curr, value]) => {
      const key = curr as keyof ResponseRangeLabels;
      if (key === 'minimumValueLabel') {
        return {
          ...acc,
          min: value as ResponseRangeLabels[typeof key],
        };
      }

      if (key === 'maximumValueLabel') {
        return {
          ...acc,
          max: value as ResponseRangeLabels[typeof key],
        };
      }

      if (key === 'middleValueLabels') {
        return {
          ...acc,
          mid_values: value as ResponseRangeLabels[typeof key],
        };
      }

      return acc;
    },
    {} as Question['dimensions'][number],
  );

  return [dimension, dimension];
};
