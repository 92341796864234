import type { Question } from '@mentimeter/http-clients';
import type { Image } from '@core-api/editor/types/response';

export const questionImageToSlideImage = (
  question: Question | undefined,
): Image | undefined => {
  if (question && question.image && question.question_image_url) {
    return {
      imageId: question.image.id,
      altText: question.image.alt_description ?? '',
      path: (question.image as unknown as { s3_key: string }).s3_key,
      originalHeight: question.image.original_height ?? 0,
      originalWidth: question.image.original_width ?? 0,
      // FIXME remove type cast when api response types are updated
      presets: question.image.presets as Image['presets'],
      crop: question.image.crop
        ? {
            x: question.image.crop.x,
            y: question.image.crop.y,
            width: question.image.crop.width,
            height: question.image.crop.height,
          }
        : {
            x: 0,
            y: 0,
            width: question.image.original_width ?? 0,
            height: question.image.original_height ?? 0,
          },
    };
  }

  return undefined;
};
