import type { Question } from '@mentimeter/http-clients';
import type { InteractiveContent } from '@mentimeter/editor-schema/api-types-overrides';
import { questionActiveToResponseMode } from '../converters/question/question-active-to-response-mode';
import { RESPONSE_POLICY_SINGLE_RESPONSE_NO_CHOICE_RESTRICTION } from '../../constants';

export const getPrioritizationInteractiveContent = (question: Question) => {
  const content: Partial<InteractiveContent> = {
    maxEntries: null,
    scoring: null,
    countdown: null,
    interactiveContentId: `${question.id}-interactive`,
    title: question.question,
    description: question.question_description,
    choices: question.choices.map((choice) => ({
      interactiveContentChoiceId: choice.id.toString(),
      markedCorrect: choice.correct_answer,
      title: choice.label,
      image: null,
      styling: null,
      correctAnswerRange: null,
      legacyChoiceId: null,
      legacyQuestionMetadataFieldId: null,
    })),
    responseMode: questionActiveToResponseMode(question.active),
    responsePolicy: RESPONSE_POLICY_SINGLE_RESPONSE_NO_CHOICE_RESTRICTION,
  };

  return content;
};
