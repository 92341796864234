import type { Slide as PresentationSlide } from '@mentimeter/presentation-schema/api-types-overrides';
import { commonSlidePropertyKeys } from '../../common/constants';

export const presentationSlidePropertyKeys = [
  'slideId',
  'speakerNotes',
  'legacyQuestionAdminKey',
  'legacyQuestionPublicKey',
  'qrCodeVisibility',
  ...commonSlidePropertyKeys,
] as const satisfies Array<keyof PresentationSlide>;
