import type { Question } from '@mentimeter/http-clients';
import {
  RESPONSE_POLICY_SINGLE_RESPONSE_LIMITED_CHOICES,
  RESPONSE_POLICY_SINGLE_RESPONSE_SINGLE_CHOICE,
} from '../../../constants';

export const questionMaxVotesToChoicesResponsePolicy = (
  maxVotes: Question['max_votes'],
) => {
  if (maxVotes === 1) {
    return RESPONSE_POLICY_SINGLE_RESPONSE_SINGLE_CHOICE;
  }

  return RESPONSE_POLICY_SINGLE_RESPONSE_LIMITED_CHOICES;
};
