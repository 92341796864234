import type { Layout } from '@mentimeter/http-clients';
import type { StaticContentStylingSchema } from '@mentimeter/schema/static-content';
import type { TextAlignmentSchema } from '@mentimeter/schema/partials';

const slideHorizontalAlignmentToQuestionMap: Record<
  TextAlignmentSchema['textHorizontalAlign'],
  Layout['alignment_x']
> = {
  left: 0,
  center: 1,
  right: 2,
};

const slideVerticalAlignmentToQuestionMap: Record<
  TextAlignmentSchema['textVerticalAlign'],
  Layout['alignment_y']
> = {
  top: 0,
  center: 1,
  bottom: 2,
};

const getQuestionAlignmentY = (styling: StaticContentStylingSchema) => {
  const slideTextVerticalAlign =
    'textVerticalAlign' in styling ? styling.textVerticalAlign : 'center';

  return slideTextVerticalAlign
    ? slideVerticalAlignmentToQuestionMap[slideTextVerticalAlign]
    : 1;
};

const getQuestionAlignmentX = (styling: StaticContentStylingSchema) => {
  const slideTextHorizontalAlign =
    'textHorizontalAlign' in styling ? styling.textHorizontalAlign : 'center';

  return slideTextHorizontalAlign
    ? slideHorizontalAlignmentToQuestionMap[slideTextHorizontalAlign]
    : 1;
};

export const textAlignmentToQuestionTextAlignment = (
  styling: StaticContentStylingSchema,
) => {
  return {
    alignmentX: getQuestionAlignmentX(styling),
    alignmentY: getQuestionAlignmentY(styling),
  };
};
