import type { Question } from '@mentimeter/http-clients';
import type { DocumentStaticContentSchema } from '@mentimeter/schema/static-content';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';

export const getDocumentStaticContent = (question: Question) => {
  const content: DocumentStaticContentSchema = {
    version: 'v1',
    type: 'document',
    heading: question.question,
    image: questionToSlideImageReference(question),
    styling: {
      version: 'v1',
      ...questionTextLayoutToSlideTextLayout(question.layout),
    },
  };

  return content;
};
