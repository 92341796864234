import type { InteractiveContent } from '@mentimeter/editor-schema/api-types-overrides';
import type { Question } from '@mentimeter/http-clients';
import type { MultipleChoiceInteractiveContentStylingSchema } from '@mentimeter/schema/interactive-content-styling';
import { questionActiveToResponseMode } from '../converters/question/question-active-to-response-mode';
import { convertLegacyChoiceImage } from '../converters/question/question-images-to-slide-images';
import { questionMaxVotesToChoicesResponsePolicy } from '../converters/question/question-max-votes-to-choices-response-policy';
import { questionShowCorrectToSlideCorrectAnswerMode } from '../converters/question/question-show-correct-to-slide-correct-answer-mode';
import { QUESTION_SUBTYPE_TO_SLIDE_VISUALIZATION } from '../converters/question/question-subtype-to-slide-visualization';

export const getChoicesInteractiveContent = (question: Question) => {
  const content: Partial<InteractiveContent> = {
    maxEntries: getMaxEntries(question),
    scoring: null,
    countdown: null,
    interactiveContentId: `${question.id}-interactive`,
    title: question.question,
    description: question.question_description,
    styling: {
      type: 'multiple-choice',
      visualization:
        (question.sub_type
          ? (QUESTION_SUBTYPE_TO_SLIDE_VISUALIZATION[
              question.sub_type
            ] as MultipleChoiceInteractiveContentStylingSchema['visualization'])
          : undefined) ?? 'bar_chart',
      responseCountDisplayMode: question.percent_default
        ? 'percentage'
        : 'absolute',
      correctAnswerMode: questionShowCorrectToSlideCorrectAnswerMode(
        question.show_correct,
      ),
      styledAttributes: {
        title: question.question_styled,
      },
    },
    choices: question.choices.map((choice) => ({
      image: convertLegacyChoiceImage(choice),
      interactiveContentChoiceId: choice.id.toString(),
      markedCorrect: choice.correct_answer,
      title: choice.label,
      styling: null,
      correctAnswerRange: null,
      legacyChoiceId: null,
      legacyQuestionMetadataFieldId: null,
    })),
    responseMode: questionActiveToResponseMode(question.active),
    responsePolicy: questionMaxVotesToChoicesResponsePolicy(question.max_votes),
  };

  return content;
};

const getMaxEntries = (question: Question) => {
  if (question.max_votes === 1) {
    return null;
  }

  if (question.max_votes === -1) {
    return question.choices.length;
  }

  return question.max_votes;
};
