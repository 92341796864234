import type { Question } from '@mentimeter/http-clients';
import type { BulletsStaticContentSchema } from '@mentimeter/schema/static-content';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionBulletSettingsToBulletsStyling } from '../converters/question/question-bullet-settings-to-bullets-styling';

export const getBulletsStaticContent = (question: Question) => {
  const content: BulletsStaticContentSchema = {
    version: 'v1',
    type: 'list',
    heading: question.question,
    meta: question.title_meta,
    image: questionToSlideImageReference(question),
    list: {
      items: question.choices.map((choice) => ({
        id: choice.id.toString(),
        content: choice.label,
      })),
    },
    styling: {
      version: 'v1',
      ...questionTextLayoutToSlideTextLayout(question.layout),
      ...questionBulletSettingsToBulletsStyling(question),
    },
  };

  return content;
};
