import type { InteractiveContent } from '@mentimeter/editor-schema/api-types-overrides';
import type { Question } from '@mentimeter/http-clients';
import type { OpenEndedInteractiveContentStylingSchema } from '@mentimeter/schema/interactive-content-styling';
import {
  RESPONSE_POLICY_NO_RESTRICTION,
  RESPONSE_POLICY_SINGLE_RESPONSE_SINGLE_CHOICE,
} from '../../constants';
import { questionActiveToResponseMode } from '../converters/question/question-active-to-response-mode';
import { QUESTION_SUBTYPE_TO_SLIDE_VISUALIZATION } from '../converters/question/question-subtype-to-slide-visualization';

export const getOpenEndedInteractiveContent = (question: Question) => {
  const content: Partial<InteractiveContent> = {
    maxEntries: null,
    scoring: null,
    countdown: question.countdown_to_vote,
    interactiveContentId: `${question.id}-interactive`,
    title: question.question,
    description: question.question_description,
    styling: {
      type: 'open-ended',
      visualization:
        (QUESTION_SUBTYPE_TO_SLIDE_VISUALIZATION[
          question.sub_type as keyof typeof QUESTION_SUBTYPE_TO_SLIDE_VISUALIZATION
        ] as OpenEndedInteractiveContentStylingSchema['visualization']) ??
        'speech_bubbles',
    },
    voteSettings: null,
    choices: question.choices.map((choice) => ({
      interactiveContentChoiceId: choice.id.toString(),
      markedCorrect: choice.correct_answer,
      title: choice.label,
      styling: null,
      image: null,
      correctAnswerRange: null,
      legacyChoiceId: null,
      legacyQuestionMetadataFieldId: null,
    })),
    responseMode: questionActiveToResponseMode(question.active),
    responsePolicy: getResponsePolicy(question),
    responsePointQuota: null,
  };

  return content;
};

const getResponsePolicy = (question: Question) => {
  if (question.multiple_votes) {
    return RESPONSE_POLICY_NO_RESTRICTION;
  }

  return RESPONSE_POLICY_SINGLE_RESPONSE_SINGLE_CHOICE;
};
