import type { Question } from '@mentimeter/http-clients';
import type { Image } from '@core-api/editor/types/response';

export const imageToQuestionImage = (
  image: Image,
): NonNullable<Question['image']> => {
  return {
    url: image.presets?.source?.url,
    id: image.imageId,
    alt_description: image.altText ?? '',
    original_height: image.originalHeight,
    original_width: image.originalWidth,
    presets: image.presets as never,
    crop: image.crop
      ? {
          height: image.crop.height,
          width: image.crop.width,
          x: image.crop.x,
          y: image.crop.y,
          natural_height: image.originalHeight,
          natural_width: image.originalWidth,
        }
      : null,
    _object: 'image',
    _url: image.presets?.source?.url,
  };
};
