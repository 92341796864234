import type { Question } from '@mentimeter/http-clients';
import type {
  QuestionWithSlide as PresentationQuestionWithSlide,
  Slide as PresentationSlide,
} from '@mentimeter/presentation-schema/api-types-overrides';
import { isProxyInstance } from '../../../utils/is-proxy-instance';
import { getCommonSlideCompatibilityHandler } from '../../common/get-common-slide-compatibility-handler';
import { presentationSlidePropertyKeys } from './constants';
import { mapPresentationSlidePropertyToQuestionProperty } from './map-presentation-slide-property-to-question-property';

export function createPresentationSlideCompatibilityLayer(
  target: Question | PresentationSlide,
): PresentationQuestionWithSlide {
  if (isProxyInstance(target)) {
    return target as PresentationQuestionWithSlide;
  }

  return new Proxy(
    target,
    getCommonSlideCompatibilityHandler(
      presentationSlidePropertyKeys,
      mapPresentationSlidePropertyToQuestionProperty,
    ),
  ) as PresentationQuestionWithSlide;
}
