import type { Question } from '@mentimeter/http-clients';
import type { InteractiveContent } from '@mentimeter/editor-schema/api-types-overrides';
import {
  CORRECT_AREA_DISABLED,
  CORRECT_AREA_ENABLED,
} from '../../../constants';

export const questionCorrectAreaToInteractiveContentCorrectArea = (
  correctArea: Question['correct_area'],
  showCorrect: Question['show_correct'],
): InteractiveContent['correctArea'] => {
  if (!correctArea) {
    return {
      area: {
        left: 0,
        top: 0,
        right: 1,
        bottom: 1,
      },
      correctAreaMode: showCorrect
        ? CORRECT_AREA_ENABLED
        : CORRECT_AREA_DISABLED,
    };
  }

  const xValues = correctArea.map((point) => point[0]) as Array<number>;
  const yValues = correctArea.map((point) => point[1]) as Array<number>;

  const xMin = Math.min(...xValues);
  const xMax = Math.max(...xValues);
  const yMin = Math.min(...yValues);
  const yMax = Math.max(...yValues);

  return {
    correctAreaMode: showCorrect ? CORRECT_AREA_ENABLED : CORRECT_AREA_DISABLED,
    area: {
      top: yMin,
      left: xMin,
      right: xMax,
      bottom: yMax,
    },
  };
};
