import {
  SLIDE_HIDDEN,
  SLIDE_VISIBLE,
  type SlideVisibility,
} from '../../../constants';

export const questionSkippedToSlideVisibility = (
  skipped: boolean,
): SlideVisibility => {
  return skipped ? SLIDE_HIDDEN : SLIDE_VISIBLE;
};
