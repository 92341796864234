import type { QuestionWithSlide } from '@mentimeter/editor-schema/api-types-overrides';
import type {
  ModuleId,
  Question,
  QuestionChoice,
} from '@mentimeter/http-clients';
import { getOrSetCache, type WithCache } from '../../utils/get-or-set-cache';
import { isLegacyImageProps } from '../../utils/is-question-image-props';
import type { CompatibilitySlide } from '../../compatibility-types';
import { layoutToQuestionLayout } from './../converters/static-content/layout-to-question-layout';
import { typeToModuleId } from './../converters/static-content/type-to-module-id';
import {
  getCommonQuestionProperty,
  isCommonQuestionProperty,
  type CommonQuestionProperty,
} from './get-common-question-property';
import {
  getInteractiveQuestionProperty,
  isInteractiveQuestionProperty,
} from './get-interactive-question-property';
import { getLegacyImageProperty } from './get-legacy-image-property';
import { getQuestionBigProperty } from './get-question-big-property';
import { getQuestionBulletsProperty } from './get-question-bullets-property';
import { getQuestionChoicesProperty } from './get-question-choices-property';
import { getQuestionDocumentProperty } from './get-question-document-property';
import { getQuestionFreeTextProperty } from './get-question-free-text-property';
import { getQuestionGoogleSlidesProperty } from './get-question-google-slides-property';
import { getQuestionGuessTheNumberProperty } from './get-question-guess-the-number-property';
import { getQuestionHeadingProperty } from './get-question-heading-property';
import { getQuestionImageProperty } from './get-question-image-property';
import { getQuestionInstructionsProperty } from './get-question-instructions-property';
import { getQuestionLeaderboardProperty } from './get-question-leaderboard-property';
import { getQuestionMiroProperty } from './get-question-miro-property';
import { getQuestionNumberProperty } from './get-question-number-property';
import { getQuestionOpenEndedProperty } from './get-question-open-ended-property';
import { getQuestionParagraphProperty } from './get-question-paragraph-property';
import { getQuestionPinOnImageProperty } from './get-question-pin-on-image-property';
import { getQuestionPowerPointProperty } from './get-question-powerpoint-property';
import { getQuestionQuestionsFromAudienceProperty } from './get-question-questions-from-audience-property';
import { getQuestionQuickFormProperty } from './get-question-quick-form-property';
import { getQuestionQuoteProperty } from './get-question-quote-property';
import { getQuestionRankingProperty } from './get-question-ranking-property';
import { getQuestionRatingProperty } from './get-question-rating-property';
import { getQuestionScalesProperty } from './get-question-scales-property';
import { getQuestionVideoProperty } from './get-question-video-property';
import { getQuestionWordcloudProperty } from './get-question-word-cloud-property';

const CHOICES_FALLBACK: Array<QuestionChoice> = [];

const propertyByModuleIdMap: Partial<
  Record<
    ModuleId,
    (
      target: CompatibilitySlide,
      prop: keyof Omit<Question, CommonQuestionProperty>,
    ) => unknown
  >
> = {
  'free-text': getQuestionFreeTextProperty,
  'google-slides': getQuestionGoogleSlidesProperty,
  'numerical-question': getQuestionGuessTheNumberProperty,
  'pin-on-image': getQuestionPinOnImageProperty,
  'quiz-leaderboard': getQuestionLeaderboardProperty,
  big: getQuestionBigProperty,
  bullets: getQuestionBulletsProperty,
  choices: getQuestionChoicesProperty,
  document: getQuestionDocumentProperty,
  heading: getQuestionHeadingProperty,
  image: getQuestionImageProperty,
  instructions: getQuestionInstructionsProperty,
  metadata: getQuestionQuickFormProperty,
  miro: getQuestionMiroProperty,
  number: getQuestionNumberProperty,
  open: getQuestionOpenEndedProperty,
  paragraph: getQuestionParagraphProperty,
  powerpoint: getQuestionPowerPointProperty,
  qfa: getQuestionQuestionsFromAudienceProperty,
  quote: getQuestionQuoteProperty,
  ranking: getQuestionRankingProperty,
  rating: getQuestionRatingProperty,
  scales: getQuestionScalesProperty,
  video: getQuestionVideoProperty,
  wordcloud: getQuestionWordcloudProperty,
};

export const getQuestionProperty = (
  slide: CompatibilitySlide,
  prop: keyof Question,
) => {
  if (isLegacyImageProps(prop)) {
    return getLegacyImageProperty(slide, prop);
  }
  const staticContent = slide.staticContent;
  if (
    prop === 'layout' &&
    staticContent &&
    typeof staticContent === 'object' &&
    'layout' in staticContent &&
    'image' in staticContent &&
    staticContent.layout &&
    staticContent.image
  ) {
    if (staticContent && 'styling' in staticContent) {
      const questionLayout = layoutToQuestionLayout(
        staticContent.layout,
        staticContent.image.imageLayoutPosition,
        Boolean(staticContent.image.imageInset),
        staticContent.styling,
      );

      const cacheKey = JSON.stringify(questionLayout);
      return getOrSetCache(
        slide as WithCache<QuestionWithSlide>,
        prop,
        cacheKey,
        questionLayout,
      );
    }
  }

  if (isCommonQuestionProperty(prop)) {
    return getCommonQuestionProperty(slide, prop);
  }

  if (slide.interactiveContents[0] && isInteractiveQuestionProperty(prop)) {
    return getInteractiveQuestionProperty(slide, prop);
  }

  if (slide.staticContent.type === 'list' && prop === 'choices') {
    const listChoices: Array<QuestionChoice> =
      slide.staticContent.list.items.map((item, index) => ({
        id: index,
        label: item.content,
        correct_answer: false,
        position: index,
      }));

    const cacheKey = JSON.stringify(listChoices);
    return getOrSetCache(
      slide as WithCache<QuestionWithSlide>,
      prop,
      cacheKey,
      listChoices,
    );
  }
  // in the old model even non interactive questions had choices, so we need to fallback to this
  if (!slide.interactiveContents[0] && prop === 'choices') {
    return CHOICES_FALLBACK;
  }

  const module_id = typeToModuleId(staticContent.type);
  const propertyFn = propertyByModuleIdMap[module_id];
  if (typeof propertyFn === 'function') {
    return propertyFn(slide, prop);
  }

  // This is commented out because we have to many warnings to fix and we don't want to pollute sentry and console.
  // const error = new UnsupportedModuleIdError(module_id);
  // captureException(error);

  // if (process.env.NODE_ENV === 'development') {
  //   // eslint-disable-next-line no-console
  //   console.warn(error.message);
  // }
};
