import type { Question } from '@mentimeter/http-clients';
import type { BulletsStaticContentSchema } from '@mentimeter/schema/static-content';

export const questionBulletSettingsToBulletsStyling = (
  question: Question,
): Pick<
  BulletsStaticContentSchema['styling'],
  'listDisplayMode' | 'listStyle'
> => ({
  listDisplayMode: question.show_all_bullets ? 'simultaneous' : 'sequential',
  listStyle: question.numbered_list ? 'numbered' : 'bullet',
});
