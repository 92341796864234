import type { Layout } from '@mentimeter/http-clients';
import type { TextAlignmentSchema } from '@mentimeter/schema/partials';

interface StaticContentTextLayout extends Omit<TextAlignmentSchema, 'version'> {
  textSizeOffset: Layout['font_size_offset'];
}

const legacyTextXAlignmentMap: Record<
  Layout['alignment_x'],
  TextAlignmentSchema['textHorizontalAlign']
> = {
  '0': 'left',
  '1': 'center',
  '2': 'right',
} as const;

const legacyTextYAlignmentMap: Record<
  Layout['alignment_y'],
  TextAlignmentSchema['textVerticalAlign']
> = {
  '0': 'top',
  '1': 'center',
  '2': 'bottom',
} as const;

export const questionTextLayoutToSlideTextLayout = (
  layout: Layout | undefined,
): StaticContentTextLayout => {
  if (!layout) {
    return {
      textHorizontalAlign: 'center',
      textVerticalAlign: 'center',
      textSizeOffset: 0,
    };
  }

  return {
    textHorizontalAlign: legacyTextXAlignmentMap[layout.alignment_x],
    textVerticalAlign: legacyTextYAlignmentMap[layout.alignment_y],
    textSizeOffset: layout.font_size_offset,
  };
};
