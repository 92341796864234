import type { ModuleId, Question } from '@mentimeter/http-clients';
import type { StaticContentSchema } from '@mentimeter/schema/static-content';
import { UnsupportedModuleIdError } from '../../backward-compatibility/errors/unsupported-module-id-error';
import { getBigStaticContent } from './get-big-static-content';
import { getBulletsStaticContent } from './get-bullets-static-content';
import { getChoicesStaticContent } from './get-choices-static-content';
import { getDocumentStaticContent } from './get-document-static-content';
import { getFreeTextStaticContent } from './get-free-text-static-content';
import { getGoogleSlidesStaticContent } from './get-google-slides-static-content';
import { getGuessTheNumberQuestionStaticContent } from './get-guess-the-number-static-content';
import { getHeadingStaticContent } from './get-heading-static-content';
import { getImageStaticContent } from './get-image-static-content';
import { getInstructionsStaticContent } from './get-instructions-static-content';
import { getLeaderboardStaticContent } from './get-leaderboard-static-content';
import { getMiroStaticContent } from './get-miro-static-content';
import { getNumberStaticContent } from './get-number-static-content';
import { getOpenEndedStaticContent } from './get-open-ended-static-content';
import { getParagraphStaticContent } from './get-paragraph-static-content';
import { getPinOnImageStaticContent } from './get-pin-on-image-static-content';
import { getPowerPointStaticContent } from './get-powerpoint-static-content';
import { getPrioritizationStaticContent } from './get-prioritization-static-content';
import { getQuestionsFromAudienceStaticContentSchemaStaticContent } from './get-questions-from-audience-static-content';
import { getQuickFormStaticContent } from './get-quick-form-static-content';
import { getQuizChoicesStaticContent } from './get-quiz-choices-static-content';
import { getQuizOpenStaticContent } from './get-quiz-open-static-content';
import { getQuoteStaticContent } from './get-quote-static-content';
import { getRankingStaticContent } from './get-ranking-static-content';
import { getRatingStaticContent } from './get-rating-static-content';
import { getScalesStaticContent } from './get-scales-static-content';
import { getVideoStaticContent } from './get-video-static-content';
import { getWordcloudStaticContent } from './get-word-cloud-static-content';

const staticContentByModuleIdMap: Partial<
  Record<ModuleId, (question: Question) => StaticContentSchema>
> = {
  '100-points': getPrioritizationStaticContent,
  'free-text': getFreeTextStaticContent,
  'google-slides': getGoogleSlidesStaticContent,
  'numerical-question': getGuessTheNumberQuestionStaticContent,
  'pin-on-image': getPinOnImageStaticContent,
  'quiz-choices': getQuizChoicesStaticContent,
  'quiz-leaderboard': getLeaderboardStaticContent,
  'quiz-open': getQuizOpenStaticContent,
  big: getBigStaticContent,
  bullets: getBulletsStaticContent,
  choices: getChoicesStaticContent,
  document: getDocumentStaticContent,
  heading: getHeadingStaticContent,
  image: getImageStaticContent,
  instructions: getInstructionsStaticContent,
  metadata: getQuickFormStaticContent,
  miro: getMiroStaticContent,
  number: getNumberStaticContent,
  open: getOpenEndedStaticContent,
  paragraph: getParagraphStaticContent,
  powerpoint: getPowerPointStaticContent,
  qfa: getQuestionsFromAudienceStaticContentSchemaStaticContent,
  quote: getQuoteStaticContent,
  ranking: getRankingStaticContent,
  rating: getRatingStaticContent,
  scales: getScalesStaticContent,
  video: getVideoStaticContent,
  wordcloud: getWordcloudStaticContent,
};

export const getQuestionStaticContent = (
  question: Question,
): StaticContentSchema => {
  const contentFn = staticContentByModuleIdMap[question.module_id];
  if (typeof contentFn === 'function') {
    return contentFn(question);
  }

  throw new UnsupportedModuleIdError(question.module_id);
};
