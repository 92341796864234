import type { Question } from '@mentimeter/http-clients';
import { UnsupportedSlidePropertyError } from '../backward-compatibility/errors/unsupported-slide-property-error';
import type {
  CompatibilitySlide,
  CompatibilityMigratedQuestionWithSlide as QuestionWithSlide,
} from '../compatibility-types';
import { getOrSetCache, type WithCache } from '../utils/get-or-set-cache';
import { questionColorsToSlideDesignColors } from './converters/question/question-colors-to-slide-design-colors';
import { questionImagesToSlideImages } from './converters/question/question-images-to-slide-images';
import { getQuestionInteractiveContent } from './interactive-content/get-question-interactive-content';
import { getQuestionStaticContent } from './static-content/get-question-static-content';
import { questionHideJoinInstructionsToQrCodeVisibility } from './converters/question/question-hide-join-instructions-to-qrcode-visibility';
import { questionHideVotingInstructionsBarToInstructionsBarVisibility } from './converters/question/question-hide-instructions-bar-to-instructions-bar-visibility';
import { questionHideResultsToResponseVisibility } from './converters/question/question-hide-results-to-response-visibility';
import { questionSkippedToSlideVisibility } from './converters/question/question-skipped-to-visibility';

export const getSlideProperty = (
  question: Question,
  key: keyof CompatibilitySlide,
) => {
  switch (key) {
    case 'staticContent': {
      const staticContent = getQuestionStaticContent(question);
      const key = JSON.stringify(staticContent);
      return getOrSetCache(
        question as WithCache<QuestionWithSlide>,
        'staticContent',
        key,
        staticContent,
      );
    }

    case 'interactiveContents': {
      const interactiveContent = getQuestionInteractiveContent(question) || [];
      const key = JSON.stringify(interactiveContent);
      const result = getOrSetCache(
        question as WithCache<QuestionWithSlide>,
        'interactiveContents',
        key,
        [interactiveContent],
      );
      return result;
    }

    case 'images': {
      const images = questionImagesToSlideImages(question as QuestionWithSlide);
      const key = JSON.stringify(images);
      return getOrSetCache(
        question as WithCache<QuestionWithSlide>,
        'images',
        key,
        images,
      );
    }

    case 'design': {
      const questionTheme = (question as QuestionWithSlide).theme_settings;
      const seriesTheme = (question as QuestionWithSlide)
        .frontendCompatibilityData?.seriesTheme;

      const design = {
        colors: questionColorsToSlideDesignColors({
          ...seriesTheme,
          ...questionTheme,
        }),
      };
      const key = JSON.stringify(design);
      return getOrSetCache(
        question as WithCache<QuestionWithSlide>,
        'design',
        key,
        design,
      );
    }

    case 'qrCodeVisibility':
      return questionHideJoinInstructionsToQrCodeVisibility(
        question.hide_join_instructions,
      );

    case 'instructionsBarVisibility':
      return questionHideVotingInstructionsBarToInstructionsBarVisibility(
        question.hide_voting_instructions,
      );

    case 'responseVisibility':
      return questionHideResultsToResponseVisibility(question.hide_results);

    case 'visibility':
      return questionSkippedToSlideVisibility(question.skip_slide);

    case 'legacyQuestionPublicKey':
      return question.public_key;

    default:
      if (!Reflect.has(question, key)) {
        if (process.env.NODE_ENV === 'development') {
          const error = new UnsupportedSlidePropertyError(key);
          // eslint-disable-next-line no-console
          console.warn(error.message);
        }
      }
      return undefined;
  }
};
