import { getOrSetCache, type WithCache } from '../../../utils/get-or-set-cache';
import type { CompatibilitySlide } from '../../../compatibility-types';
import { designToQuestionThemeSettings } from '../../converters/slide';

export function getCachedThemeSettingsProperty(slide: CompatibilitySlide) {
  const settings = designToQuestionThemeSettings(slide.design);
  const cacheKey = JSON.stringify(settings);
  return getOrSetCache(
    slide as WithCache<CompatibilitySlide>,
    'theme_settings',
    cacheKey,
    settings,
  );
}
