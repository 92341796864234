import type { Theme } from '@mentimeter/http-clients';
import type { PutDesignColorsRequest } from '@core-api/editor/editor/slide/{slide_id}/design-colors';

type PartialUndefined<T> = {
  [P in keyof T]?: T[P] | undefined;
};

type QuestionColors = PartialUndefined<
  Pick<
    Theme,
    | 'backdrop_alpha'
    | 'background_color'
    | 'bar_color'
    | 'text_color'
    | 'line_color'
  >
>;

export const questionColorsToSlideDesignColors = (
  colors: QuestionColors,
): Pick<
  PutDesignColorsRequest,
  | 'backgroundAlpha'
  | 'backgroundColor'
  | 'visualizationColors'
  | 'primaryColor'
  | 'textColor'
  | 'lineColor'
> => {
  return {
    backgroundAlpha: colors.backdrop_alpha || null,
    backgroundColor: colors.background_color || null,
    visualizationColors: colors.bar_color || null,
    primaryColor: colors.bar_color?.[0] || null,
    textColor: colors.text_color || null,
    lineColor: colors.line_color || null,
  };
};
