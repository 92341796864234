import type { Slide as EditorSlide } from '@mentimeter/editor-schema/api-types-overrides';
import type { Slide as PresentationSlide } from '@mentimeter/presentation-schema/api-types-overrides';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';

export const commonSlidePropertyKeys = [
  'staticContent',
  'interactiveContents',
  'images',
  'design',
] as const satisfies Array<
  keyof (EditorSlide | PresentationSlide | VotingSlide)
>;
