import type { Question } from '@mentimeter/http-clients';
import type { ScalesInteractiveContentStylingSchema as Styling } from '@mentimeter/schema/interactive-content-styling';
import type { InteractiveContent } from '@mentimeter/editor-schema/api-types-overrides';
import { QUESTION_SUBTYPE_TO_SLIDE_VISUALIZATION } from '../converters/question/question-subtype-to-slide-visualization';
import { questionDimensionsToSlideScaleResponseRangeLabels } from '../converters/question/question-dimensions-to-slide-scale-response-range-labels';
import {
  RESPONSE_POLICY_SINGLE_RESPONSE_ALL_CHOICES,
  RESPONSE_POLICY_SINGLE_RESPONSE_NO_CHOICE_RESTRICTION,
} from '../../constants';

export const getScalesInteractiveContent = (question: Question) => {
  const content: Partial<InteractiveContent> = {
    maxEntries: question.max_votes,
    scoring: null,
    countdown: question.countdown_to_vote,
    interactiveContentId: `${question.id}-interactive`,
    title: question.question,
    description: question.question_description,
    styling: {
      type: 'scales',
      visualization:
        (QUESTION_SUBTYPE_TO_SLIDE_VISUALIZATION[
          question.sub_type as keyof typeof QUESTION_SUBTYPE_TO_SLIDE_VISUALIZATION
        ] as Styling['visualization']) ?? 'slider-chart',
      showAverage: question.scales_total_average,
      responseRangeLabels: questionDimensionsToSlideScaleResponseRangeLabels(
        question.dimensions,
      ),
    },
    choices: question.choices.map((choice) => ({
      interactiveContentChoiceId: choice.id.toString(),
      markedCorrect: choice.correct_answer,
      title: choice.label,
      styling: null,
      image: null,
      correctAnswerRange: null,
      legacyChoiceId: null,
      legacyQuestionMetadataFieldId: null,
    })),
    responseRange: question.range,

    responsePolicy: question.hide_skip
      ? RESPONSE_POLICY_SINGLE_RESPONSE_ALL_CHOICES
      : RESPONSE_POLICY_SINGLE_RESPONSE_NO_CHOICE_RESTRICTION,
  };

  return content;
};
