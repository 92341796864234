import type { Question } from '@mentimeter/http-clients';
import type { InteractiveContent } from '@mentimeter/editor-schema/api-types-overrides';
import { questionActiveToResponseMode } from '../converters/question/question-active-to-response-mode';
import {
  RESPONSE_POLICY_NO_RESTRICTION,
  RESPONSE_POLICY_SINGLE_RESPONSE_SINGLE_CHOICE,
} from '../../constants';

export const getQuickFormInteractiveContent = (question: Question) => {
  const content: Partial<InteractiveContent> = {
    maxEntries: null,
    scoring: null,
    countdown: question.countdown_to_vote,
    interactiveContentId: `${question.id}-interactive`,
    title: question.question,
    description: question.question_description,
    styling: null,
    voteSettings: null,
    choices: [...(question.fields ?? [])]
      .sort((a, b) => a.position - b.position)
      .map((field) => ({
        correctAnswerRange: null,
        interactiveContentChoiceId: field.id.toString(),
        markedCorrect: false,
        title: field.label,
        styling: {
          type: 'quick-form',
          subType: field.type,
          isRequired: field.is_required,
          options: field.options,
        },
        image: null,
        legacyChoiceId: null,
        legacyQuestionMetadataFieldId: null,
      })),
    responseMode: questionActiveToResponseMode(question.active),
    responsePolicy: getResponsePolicy(question),
    responsePointQuota: null,
  };

  return content;
};

const getResponsePolicy = (question: Question) => {
  if (question.multiple_votes) {
    return RESPONSE_POLICY_NO_RESTRICTION;
  }

  return RESPONSE_POLICY_SINGLE_RESPONSE_SINGLE_CHOICE;
};
