import { MentiError } from '@mentimeter/errors/sentry';
import type { QuestionSubType } from '@mentimeter/http-clients';
import type { Visualization } from '@mentimeter/schema/partials';
import { invert } from 'lodash';
// TODO: Move to common constans, since backward compatibility should not depend on forward compatibility
import { QUESTION_SUBTYPE_TO_SLIDE_VISUALIZATION } from '../../../forward-compatibility/converters/question/question-subtype-to-slide-visualization';

const SLIDE_VISUALIZATION_TO_QUESTION_SUBTYPE = invert(
  QUESTION_SUBTYPE_TO_SLIDE_VISUALIZATION,
) as Partial<Record<Visualization, QuestionSubType>>;

export const visualizationToQuestionSubType = (
  visualization: Visualization | null,
) => {
  if (!visualization) {
    return null;
  }

  const subType = SLIDE_VISUALIZATION_TO_QUESTION_SUBTYPE[visualization];

  if (!subType) {
    throw new MentiError(
      `slideVisualizationToQuestionSubType: Unknown visualization ${visualization}`,
      { feature: 'compatibility-layer' },
    );
  }

  return subType;
};
