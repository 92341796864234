import type { Question } from '@mentimeter/http-clients';
import type { PrioritizationStaticContentSchema } from '@mentimeter/schema/static-content';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';

export const getPrioritizationStaticContent = (question: Question) => {
  const { textSizeOffset } = questionTextLayoutToSlideTextLayout(
    question.layout,
  );

  const content: PrioritizationStaticContentSchema = {
    type: 'prioritization',
    meta: question.title_meta,
    image: questionToSlideImageReference(question),
    styling: {
      textSizeOffset,
    },
  };

  return content;
};
