import type { Question } from '@mentimeter/http-clients';
import type { ImageStaticContentSchema as StaticContentSchema } from '@mentimeter/schema/static-content';
import type { CompatibilitySlide } from '../../compatibility-types';
import { imagePositionToQuestionSlideImageType } from '../converters/static-content';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionImageProperty = (
  target: CompatibilitySlide,
  prop: keyof Question,
) => {
  const staticContents = target.staticContent as Extract<
    StaticContentSchema,
    { type: 'image' }
  >;
  switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
    case 'question':
      return staticContents.caption;
    case 'slide_image_type':
      return imagePositionToQuestionSlideImageType(
        staticContents.imagePosition,
      );
    default:
      return undefined;
  }
};
