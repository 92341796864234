import type { Question } from '@mentimeter/http-clients';
import type { FreeTextStaticContentSchema } from '@mentimeter/schema/static-content';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';

export const getFreeTextStaticContent = (question: Question) => {
  const content: FreeTextStaticContentSchema = {
    version: 'v1',
    type: 'free-text',
    formattedBody: question.question_description_styled,
    image: questionToSlideImageReference(question),
  };

  return content;
};
