import type { InteractiveContent } from '@mentimeter/editor-schema/api-types-overrides';
import type { Question } from '@mentimeter/http-clients';
import type { CompatibilitySlide } from '../../compatibility-types';
import { NoInteractiveContentError } from '../errors/no-interactive-content-error';
import { VOTE_ON_RESPONSE_ALLOW } from '../../constants';
import type { CommonQuestionProperty } from './get-common-question-property';

const getMultipleVotesOnAnswers = (interactiveContent: InteractiveContent) => {
  const maxEntriesPerResponse =
    interactiveContent.voteSettings?.maxEntriesPerResponse;
  return (
    typeof maxEntriesPerResponse === 'undefined' ||
    maxEntriesPerResponse === null
  );
};

const getMaxVotes = (interactiveContent: InteractiveContent) => {
  if (!interactiveContent.voteSettings?.maxEntries) {
    return -1;
  }

  return interactiveContent.voteSettings.maxEntries;
};

export const getQuestionOpenEndedProperty = (
  target: CompatibilitySlide,
  prop: keyof Question,
) => {
  const interactiveContents = target.interactiveContents[0];

  if (interactiveContents) {
    switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
      case 'multiple_votes_on_answers': {
        return getMultipleVotesOnAnswers(interactiveContents);
      }
      case 'max_votes': {
        return getMaxVotes(interactiveContents);
      }
      case 'voting_on_answers': {
        return (
          interactiveContents.voteSettings?.voteOnResponsePolicy ===
          VOTE_ON_RESPONSE_ALLOW
        );
      }
      default:
        return undefined;
    }
  }
  throw new NoInteractiveContentError(target.slideId);
};
