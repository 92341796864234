import type { Question } from '@mentimeter/http-clients';
import type { PinOnImageStaticContentSchema } from '@mentimeter/schema/static-content';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';

export const getPinOnImageStaticContent = (question: Question) => {
  const content: PinOnImageStaticContentSchema = {
    version: 'v1',
    type: 'pin-on-image',
    meta: question.title_meta,
    styling: {
      version: 'v1',
      ...questionTextLayoutToSlideTextLayout(question.layout),
    },
  };

  return content;
};
