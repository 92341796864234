import type { QuestionChoice } from '@mentimeter/http-clients';
import type { InteractiveContentChoice } from '@mentimeter/editor-schema/api-types-overrides';

export const choiceToQuestionChoice = (
  choice: InteractiveContentChoice,
): QuestionChoice => {
  const image = choice.image;
  return {
    image_url: image?.presets?.source?.url,
    cropped_image_url: image?.presets?.original?.url,
    correct_answer: !!choice.markedCorrect,
    label: choice.title,
  } as QuestionChoice;
};
