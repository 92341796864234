import {
  RESPONSES_HIDDEN,
  RESPONSES_VISIBLE,
  type ResponseVisibility,
} from '../../../constants';

export const questionHideResultsToResponseVisibility = (
  hideResults: boolean,
): ResponseVisibility => {
  return hideResults ? RESPONSES_HIDDEN : RESPONSES_VISIBLE;
};
