import { MentiError, type MentiErrorOptions } from '@mentimeter/errors/sentry';
import type { StaticContentSchema } from '@mentimeter/schema/static-content';

export class MismatchedSlideTypePropertyError<
  T extends StaticContentSchema = any,
> extends MentiError {
  constructor(
    expectedType: T['type'],
    actualType: StaticContentSchema['type'],
    options?: Partial<MentiErrorOptions> | undefined,
  ) {
    const message = `Requested ${expectedType} is not a ${expectedType}: ${actualType}`;
    super(message, {
      feature: 'compatibility-layer',
      ...options,
    });
    this.name = 'MismatchedSlideTypePropertyError';
  }
}
