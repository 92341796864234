import { MentiError, type MentiErrorOptions } from '@mentimeter/errors/sentry';

export class NoInteractiveContentError extends MentiError {
  constructor(
    slideId: string,
    options?: Partial<MentiErrorOptions> | undefined,
  ) {
    const message = `Slide with id ${slideId}} does not have interactive content`;
    super(message, {
      feature: 'compatibility-layer',
      ...options,
    });
    this.name = 'NoInteractiveContentError';
  }
}
