import type { INSTRUCTIONS_BAR_VISIBLE } from '../../../constants';
import { INSTRUCTIONS_BAR_HIDDEN } from '../../../constants';

export const instructionsBarVisibilityToQuestionHideInstructions = (
  instructionsBarVisibility:
    | typeof INSTRUCTIONS_BAR_HIDDEN
    | typeof INSTRUCTIONS_BAR_VISIBLE,
) => {
  return instructionsBarVisibility === INSTRUCTIONS_BAR_HIDDEN;
};
