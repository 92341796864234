import { captureException, MentiError } from '@mentimeter/errors/sentry';
import type { Question } from '@mentimeter/http-clients';
import type { FreeTextStaticContentSchema as StaticContentSchema } from '@mentimeter/schema/static-content';
import { type CompatibilitySlide } from '../../compatibility-types';
import { isContentType } from '../../utils/is-content-type';
import { instructionsBarVisibilityToQuestionHideInstructions } from '../converters/slide/instructions-visibility-to-question-hide-instructions';
import { qrCodeVisibilityToQuestionHideJoinInstructions } from '../converters/slide/qrcode-visibility-to-question-hide-join-instructions';
import { visibilityToQuestionSkipSlide } from '../converters/slide/visibility-to-question-skip-slide';
import {
  typeToModuleId,
  typeToQuestionType,
  typeToSlideType,
} from '../converters/static-content';
import { UncommonPropertyError } from '../errors/uncommon-property-error';
import { getCachedThemeSettingsProperty } from './utils/get-cached-theme-settings-property';

export const COMMON_QUESTION_PROPERTIES = [
  'admin_key',
  'public_key',
  'hide_join_instructions',
  'hide_voting_instructions',
  'id',
  'override_theme',
  'theme_settings',
  'title_meta',
  'question_styled',
  'question_description_styled',
  'skip_slide',
  'module_id',
  'type',
  'slide_type',
] as const satisfies Array<keyof Question>;

export type CommonQuestionProperty =
  (typeof COMMON_QUESTION_PROPERTIES)[number];

export const isCommonQuestionProperty = (
  prop: keyof Question,
): prop is CommonQuestionProperty => {
  return COMMON_QUESTION_PROPERTIES.includes(prop as CommonQuestionProperty);
};

export const getCommonQuestionProperty = (
  slide: CompatibilitySlide,
  prop: CommonQuestionProperty,
) => {
  if (!isCommonQuestionProperty(prop)) {
    throw new UncommonPropertyError(prop);
  }

  const staticContents = slide.staticContent;

  if (staticContents) {
    switch (prop) {
      case 'admin_key':
        return slide.legacyQuestionAdminKey;
      case 'public_key':
        return slide.legacyQuestionPublicKey;
      case 'hide_join_instructions':
        return qrCodeVisibilityToQuestionHideJoinInstructions(
          slide.qrCodeVisibility,
        );
      case 'hide_voting_instructions':
        return instructionsBarVisibilityToQuestionHideInstructions(
          slide.instructionsBarVisibility,
        );
      case 'id':
        // shoould be legacyQuestionPublicKey for voting
        return slide.legacyQuestionAdminKey;
      case 'override_theme': {
        return slide.design.colors.custom;
      }
      case 'theme_settings': {
        return getCachedThemeSettingsProperty(slide);
      }
      case 'title_meta':
        return staticContents.meta;
      case 'skip_slide':
        return visibilityToQuestionSkipSlide(slide.visibility);
      case 'question_styled':
        /**
         * TODO: Provide from `question.slide` when available.
         * @see https://linear.app/mentimeter/issue/MX-214/
         */
        // @ts-expect-error: FIXME: question_styled is not part of the slide
        return slide.question_styled ?? {};
      case 'question_description_styled':
        return isContentType<StaticContentSchema>(
          slide.staticContent,
          'free-text' as StaticContentSchema['type'],
        )
          ? slide.staticContent.formattedBody // this is used only for free-text slide
          : {};
      case 'module_id': {
        return typeToModuleId(staticContents.type);
      }
      case 'type': {
        return typeToQuestionType(staticContents.type);
      }
      case 'slide_type': {
        return typeToSlideType(staticContents.type);
      }
      default:
        const error = new MentiError(
          `Unhandled common question property: \`${String(prop)}\``,
          {
            feature: 'compatibility-layer',
          },
        );
        captureException(error);

        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.warn(error.message);
        }

        return slide[prop];
    }
  }
};
