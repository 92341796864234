import { MentiError } from '@mentimeter/errors/sentry';
import type { QuestionSubType } from '@mentimeter/http-clients';
import type { Visualization } from '@mentimeter/schema/partials';

export const QUESTION_SUBTYPE_TO_SLIDE_VISUALIZATION: Partial<
  Record<Exclude<QuestionSubType, null>, Visualization>
> = {
  dots: 'dots',
  choices: 'bar_chart',
  donut: 'donut_chart',
  pie: 'pie_chart',
  flow: 'flowing_grid',
  open: 'speech_bubbles',
  spider: 'spider-chart',
  scales: 'slider-chart',
  'two-values': 'two-values',
  'four-values': 'four-values',
};

export const questionSubTypeToSlideVisualization = (
  subType: QuestionSubType,
) => {
  if (!subType) {
    return null;
  }

  const visualization = QUESTION_SUBTYPE_TO_SLIDE_VISUALIZATION[subType];

  if (!visualization) {
    throw new MentiError(
      `questionSubTypeToSlideVisualization: Unknown subType ${subType}`,
      { feature: 'compatibility-layer' },
    );
  }

  return visualization;
};
