import {
  INTERACTIVE_CONTENT_ACCEPTING_RESPONSES,
  type InteractiveContentResponseMode,
} from '../../../constants';

export const responseModeToQuestionActive = (
  responseMode: InteractiveContentResponseMode,
) => {
  return responseMode === INTERACTIVE_CONTENT_ACCEPTING_RESPONSES;
};
