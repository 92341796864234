import {
  INTERACTIVE_CONTENT_RESPONSE_COUNT_DISPLAY_MODE_PERCENTAGE,
  type InteractiveContentResponseCountValueDisplayMode,
} from '../../../constants';

export const responseCountDisplayModeToQuestionPercentDefault = (
  responseCountDisplayMode: InteractiveContentResponseCountValueDisplayMode,
): boolean => {
  return (
    responseCountDisplayMode ===
    INTERACTIVE_CONTENT_RESPONSE_COUNT_DISPLAY_MODE_PERCENTAGE
  );
};
