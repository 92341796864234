import type { Question } from '@mentimeter/http-clients';

export const seriesCacheKey = (seriesId: string) =>
  `coreUrl/series/${seriesId}`;

export const questionsCacheKey = (seriesId: string) =>
  `coreUrl/series/${seriesId}/questions`;

export const resultCacheKey = (
  questionId: string,
  session: number | 'current' = 'current',
) => {
  return `coreUrl/questions/${questionId}/result/session/${session}`;
};

export const quizPlayersScoreCacheKey = (questionId: string) =>
  `coreUrl/questions/${questionId}/quiz-players`;

export const quizResultCacheKey = (questionId: string) =>
  `coreUrl/questions/${questionId}/quiz-result`;

export const reactionsResultsCacheKey = (questionId: string) =>
  `coreUrl/presentation/reactions/${questionId}/results`;

export const ratingGridsCacheKey = () => `coreUrl/rating-grids`;
export const ratingGridCacheKey = (id: string) => `coreUrl/rating-grids/${id}`;

export const seriesSessionsCacheKey = (seriesId: string) =>
  `coreUrl/series/${seriesId}/sessions`;

export const questionSessionsCacheKey = (questionId: string) =>
  `coreUrl/questions/${questionId}/sessions`;

/**
 * Function generating the cache key for useSeriesHasResults used to invalidate cache correctly
 * @param {string} seriesId - The series id.
 * @param {number} minRespondents - The minimum respondents required.
 */
export const seriesHasResultsCacheKey = (
  seriesId: string,
  minRespondents?: number,
) => {
  const queryParam = minRespondents ? `min_respondents=${minRespondents}` : '';
  return `/series/${seriesId}/has-results&${queryParam}`;
};

export const getIdentifiedChoiceResultsCacheKey = (
  adminKey: Question['admin_key'],
) => `/presentation/questions/${adminKey}/identified-choice-results`;

export const getIdentifiedScalesResultsCacheKey = (
  adminKey: Question['admin_key'],
) => `/presentation/questions/${adminKey}/identified-scale-results`;

export const getIdentifiedTextResponsesCacheKey = (
  adminKey: Question['admin_key'],
) => `/presentation/questions/${adminKey}/identified-text-responses`;
