import { MentiError, type MentiErrorOptions } from '@mentimeter/errors/sentry';
import type { ModuleId } from '@mentimeter/http-clients';

export class UnsupportedModuleIdError extends MentiError {
  constructor(
    moduleId: ModuleId,
    options?: Partial<MentiErrorOptions> | undefined,
  ) {
    const message = `Unsupported module ID: ${String(moduleId)}`;

    super(message, {
      feature: 'compatibility-layer',
      ...options,
    });

    this.name = 'UnsupportedModuleIdError';
  }
}
