import type { ModuleId, Question } from '@mentimeter/http-clients';
import type { InteractiveContent } from '@mentimeter/editor-schema/api-types-overrides';
import { getChoicesInteractiveContent } from './get-choices-interactive-content';
import { getQuickFormInteractiveContent } from './get-quick-form-interactive-content';
import { getOpenEndedInteractiveContent } from './get-open-ended-interactive-content';
import { getPinOnImageInteractiveContent } from './get-pin-on-image-interactive-content';
import { getQuizChoicesInteractiveContent } from './get-quiz-choices-interactive-content';
import { getQuizOpenInteractiveContent } from './get-quiz-open-interactive-content';
import { getRankingInteractiveContent } from './get-ranking-interactive-content';
import { getRatingInteractiveContent } from './get-rating-interactive-content';
import { getScalesInteractiveContent } from './get-scales-interactive-content';
import { getWordcloudInteractiveContent } from './get-word-cloud-interactive-content';
import { getPrioritizationInteractiveContent } from './get-prioritization-interactive-content';
import { getGuessTheNumberInteractiveContent } from './get-guess-the-number-interactive-content';

const interactiveContentByModuleIdMap: Partial<
  Record<ModuleId, (question: Question) => Partial<InteractiveContent> | null>
> = {
  choices: getChoicesInteractiveContent,
  metadata: getQuickFormInteractiveContent,
  open: getOpenEndedInteractiveContent,
  'quiz-choices': getQuizChoicesInteractiveContent,
  'quiz-open': getQuizOpenInteractiveContent,
  ranking: getRankingInteractiveContent,
  rating: getRatingInteractiveContent,
  scales: getScalesInteractiveContent,
  wordcloud: getWordcloudInteractiveContent,
  '100-points': getPrioritizationInteractiveContent,
  'pin-on-image': getPinOnImageInteractiveContent,
  'numerical-question': getGuessTheNumberInteractiveContent,
};

export const getQuestionInteractiveContent = (
  question: Question,
): Partial<InteractiveContent> | null => {
  const contentFn = interactiveContentByModuleIdMap[question.module_id];
  if (typeof contentFn === 'function') {
    return contentFn(question);
  }

  return null;
};
