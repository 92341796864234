import type { Question } from '@mentimeter/http-clients';
import { memoize } from 'lodash';
import type { CompatibilitySlide } from '../../compatibility-types';
import { type getSlideProperty as getBaseSlideProperty } from '../../forward-compatibility/get-slide-property';
import { InvalidProxySetError } from '../../utils/invalid-proxy-set-error';
import { getQuestionProperty } from '../../backward-compatibility/question-properties/get-question-property';

type GetCommonSlideCompatibilityHandler = (
  slidePropertyKeys: Array<string>,
  mapSlidePropertyToQuestionProperty: typeof getBaseSlideProperty,
) => ProxyHandler<CompatibilitySlide | Question>;

export const getCommonSlideCompatibilityHandler =
  memoize<GetCommonSlideCompatibilityHandler>(
    (slidePropertyKeys, mapSlidePropertyToQuestionProperty) => ({
      get(
        target: CompatibilitySlide | Question,
        key:
          | keyof CompatibilitySlide
          | keyof Question
          | '__isProxy'
          | '__target'
          | 'isMigrated',
      ) {
        if (key === '__isProxy') {
          return true;
        }

        if (key === '__target') {
          return target;
        }
        const isTargetMigrated = Reflect.has(target, 'staticContent');

        if (key === 'isMigrated') {
          return isTargetMigrated;
        }

        if (slidePropertyKeys.includes(key)) {
          if (isTargetMigrated) {
            return target[key as keyof typeof target];
          }

          return mapSlidePropertyToQuestionProperty(
            target as Question,
            key as keyof CompatibilitySlide,
          );
        }

        if (isTargetMigrated) {
          return getQuestionProperty(target as CompatibilitySlide, key as any);
        }

        return (target as Question)[key as keyof Question];
      },
      set(_: never, key: string) {
        throw new InvalidProxySetError(key, 'slide');
      },
    }),
  );
