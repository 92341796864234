import { MentiError, type MentiErrorOptions } from '@mentimeter/errors/sentry';

export class UncommonPropertyError extends MentiError {
  constructor(
    property: string,
    options?: Partial<MentiErrorOptions> | undefined,
  ) {
    const message = `Uncommon property: ${String(property)}`;
    super(message, {
      feature: 'compatibility-layer',
      ...options,
    });
    this.name = 'UncommonPropertyError';
  }
}
