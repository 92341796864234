export type WithCache<T> = T & {
  _cache: Record<any, any>;
};

export const getOrSetCache = <T>(
  target: WithCache<T>,
  baseKey: string,
  valueHash: string,
  value: any,
) => {
  const valueHashKey = `${baseKey}Key`;
  target._cache = target._cache ?? new Map();
  if (target._cache.get(valueHashKey) !== valueHash) {
    target._cache = target._cache
      .set(baseKey, value)
      .set(valueHashKey, valueHash);
  }
  return target._cache.get(baseKey);
};
