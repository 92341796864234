import type { Question } from '@mentimeter/http-clients';
import type { LeaderboardStaticContentSchema as StaticContentSchema } from '@mentimeter/schema/static-content';
import { isContentType } from '../../utils/is-content-type';
import { MismatchedSlideTypePropertyError } from '../errors/mismatched-slide-type-property-error';
import type { CompatibilitySlide } from '../../compatibility-types';
import type { CommonQuestionProperty } from './get-common-question-property';

const staticContentSchemaType: StaticContentSchema['type'] = 'leaderboard';

export const getQuestionLeaderboardProperty = (
  target: CompatibilitySlide,
  prop: keyof Omit<Question, CommonQuestionProperty>,
) => {
  const staticContents = target.staticContent;
  if (
    isContentType<StaticContentSchema>(staticContents, staticContentSchemaType)
  ) {
    switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
      case 'question':
        return staticContents.heading;
      default:
        return undefined;
    }
  }
  throw new MismatchedSlideTypePropertyError(
    staticContentSchemaType,
    target.staticContent.type,
  );
};
