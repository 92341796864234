import { MentiError, type MentiErrorOptions } from '@mentimeter/errors/sentry';

export class InvalidProxySetError extends MentiError {
  constructor(
    key: string,
    type: 'series' | 'slide-deck' | 'slide',
    options?: MentiErrorOptions | undefined,
  ) {
    super(`Setting property(${key}) on a ${type} proxy is not supported`, {
      feature: 'compatibility-layer',
      ...options,
    });
    this.name = 'InvalidProxySetError';
  }
}
