import type { Question } from '@mentimeter/http-clients';
import type { PowerPointStaticContentSchema as StaticContentSchema } from '@mentimeter/schema/static-content';
import type { CompatibilitySlide } from '../../compatibility-types';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionPowerPointProperty = (
  target: CompatibilitySlide,
  prop: keyof Question,
) => {
  const staticContents = target.staticContent as Extract<
    StaticContentSchema,
    { type: 'powerpoint' }
  >;

  switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
    case 'module_custom_data':
      return {
        powerpoint_url: staticContents.url,
        starting_slide_number: staticContents.startingSlideNumber ?? 1,
      };
    default:
      return undefined;
  }
};
