import type { Question } from '@mentimeter/http-clients';
import type { RatingInteractiveContentStylingSchema as Styling } from '@mentimeter/schema/interactive-content-styling';

type AxisLabels = NonNullable<Pick<Styling, 'xAxisLabels' | 'yAxisLabels'>>;

export const questionDimensionsToSlideRatingAxisLabels = (
  dimensions: Question['dimensions'],
): AxisLabels => {
  return (dimensions ?? []).reduce(
    (acc, curr, i) => {
      const key: keyof AxisLabels = i === 0 ? 'xAxisLabels' : 'yAxisLabels';

      const labels: AxisLabels[keyof AxisLabels] = {
        axisLabel: curr.label ?? '',
        minimumValueLabel: curr.min ?? '',
        maximumValueLabel: curr.max ?? '',
      };

      const value: AxisLabels = {
        ...acc,
        [key]: labels,
      };

      return value;
    },
    {
      xAxisLabels: {
        axisLabel: '',
        minimumValueLabel: '',
        maximumValueLabel: '',
      },
      yAxisLabels: {
        axisLabel: '',
        maximumValueLabel: '',
        minimumValueLabel: '',
      },
    } as AxisLabels,
  );
};
