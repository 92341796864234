import type { Question } from '@mentimeter/http-clients';
import type { InteractiveContent } from '@mentimeter/editor-schema/api-types-overrides';
import { convertLegacyChoiceImage } from '../converters/question/question-images-to-slide-images';
import { RESPONSE_POLICY_SINGLE_RESPONSE_SINGLE_CHOICE } from '../../constants';
import { questionModuleCustomDataToSlideResponseRange } from '../converters/question/question-module-custom-data-to-slide-response-range';
import { questionModuleCustomDataToChoicesCorrectAnswerRange } from '../converters/question/question-module-custom-data-to-choices-correct-answer-range';

const DEFAULT_RESPONSE_RANGE_SCALE = 10;

export const getGuessTheNumberInteractiveContent = (question: Question) => {
  const content: Partial<InteractiveContent> = {
    interactiveContentId: `${question.id}-interactive`,
    title: question.question,
    description: question.question_description,
    styling: {
      type: 'guess-the-number',
      responseRangeScale:
        question.module_custom_data?.scale || DEFAULT_RESPONSE_RANGE_SCALE,
    },
    choices: question.choices.map((choice) => ({
      image: convertLegacyChoiceImage(choice),
      interactiveContentChoiceId: choice.id.toString(),
      markedCorrect: choice.correct_answer,
      title: choice.label,
      styling: null,
      legacyChoiceId: null,
      legacyQuestionMetadataFieldId: null,
      correctAnswerRange:
        questionModuleCustomDataToChoicesCorrectAnswerRange(question),
    })),
    responseRange: questionModuleCustomDataToSlideResponseRange(question),
    responsePolicy: RESPONSE_POLICY_SINGLE_RESPONSE_SINGLE_CHOICE,
  };

  return content;
};
