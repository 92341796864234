import type { Question } from '@mentimeter/http-clients';
import type { InteractiveContent } from '@mentimeter/editor-schema/api-types-overrides';
import { CORRECT_AREA_ENABLED } from '../../../constants';

export const correctAreaToQuestionCorrectArea = (
  correctArea: InteractiveContent['correctArea'],
): Pick<Question, 'correct_area' | 'show_correct'> => {
  if (correctArea?.area) {
    const { top, left, right, bottom } = correctArea.area;
    const isReset = top === 0 && left === 0 && right === 1 && bottom === 1;
    return {
      correct_area: isReset
        ? null
        : [
            [left, top],
            [right, top],
            [right, bottom],
            [left, bottom],
            [left, top],
          ],
      show_correct: correctArea.correctAreaMode === CORRECT_AREA_ENABLED,
    };
  }
  return {
    correct_area: null,
    show_correct: correctArea?.correctAreaMode === CORRECT_AREA_ENABLED,
  };
};
