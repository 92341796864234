import type { Question } from '@mentimeter/http-clients';
import type { GuessTheNumberStaticContentSchema } from '@mentimeter/schema/static-content';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';

export const getGuessTheNumberQuestionStaticContent = (question: Question) => {
  const content: GuessTheNumberStaticContentSchema = {
    version: 'v1',
    type: 'guess-the-number',
    meta: question.title_meta,
    image: questionToSlideImageReference(question),
    styling: {
      version: 'v1',
      textSizeOffset: questionTextLayoutToSlideTextLayout(question.layout)
        .textSizeOffset,
    },
  };

  return content;
};
