import type { Question } from '@mentimeter/http-clients';
import type { InteractiveContent } from '@mentimeter/editor-schema/api-types-overrides';
import { RESPONSE_POLICY_SINGLE_RESPONSE_SINGLE_CHOICE } from '../../constants';
import { questionActiveToResponseMode } from '../converters/question/question-active-to-response-mode';
import { questionCorrectAreaToInteractiveContentCorrectArea } from '../converters/question/question-correct-area-to-interactive-content-corrent-area';
import { questionImageToSlideImage } from '../converters/question/question-image-to-slide-image';

export const getPinOnImageInteractiveContent = (question: Question) => {
  const content: Partial<InteractiveContent> = {
    maxEntries: null,
    scoring: null,
    countdown: question.countdown_to_vote,
    interactiveContentId: `${question.id}-interactive`,
    title: question.question,
    description: question.question_description,
    styling: null,
    choices: [],
    image: questionImageToSlideImage(question) ?? null,
    responseMode: questionActiveToResponseMode(question.active),
    responsePolicy: RESPONSE_POLICY_SINGLE_RESPONSE_SINGLE_CHOICE,
    correctArea: questionCorrectAreaToInteractiveContentCorrectArea(
      question.correct_area,
      question.show_correct,
    ),
  };

  return content;
};
