import type { Question } from '@mentimeter/http-clients';
import type { WordcloudInteractiveContentStylingSchema } from '@mentimeter/schema/interactive-content-styling';
import type { CompatibilitySlide } from '../../compatibility-types';
import { NoInteractiveContentError } from '../errors/no-interactive-content-error';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionWordcloudProperty = (
  target: CompatibilitySlide,
  prop: keyof Question,
) => {
  const interactiveContents = target.interactiveContents?.[0];
  const interactiveContentsStyling =
    interactiveContents?.styling as WordcloudInteractiveContentStylingSchema;

  if (interactiveContents) {
    switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
      case 'max_nb_words':
        return interactiveContentsStyling.answersPerResponse;
      default:
        return undefined;
    }
  }

  throw new NoInteractiveContentError(target.slideId);
};
