import type { Layout } from '@mentimeter/http-clients';
import type {
  LayoutPositionSchema,
  LayoutSchema,
} from '@mentimeter/schema/partials';
import { getGridSize } from '../../../utils/get-grid-size';

const legacyLayoutTypeToDirectionMap: Record<
  Layout['type'],
  LayoutSchema['snapDirection']
> = {
  'full-bg': 'x',
  'side-by-side': 'x',
  'side-by-side-right': 'x',
  left: 'x',
  right: 'x',
  top: 'y',
  bottom: 'y',
  default: 'x',
};

export const convertLegacySizeToGridSpan = (
  gridSize: number,
  layout: Layout,
): LayoutPositionSchema => {
  const gridUnit = 1 / gridSize;
  const approxSizeInGridUnits = Math.max(
    1,
    Math.round(
      Number((layout.size || '33%').replace('%', '')) / 100 / gridUnit,
    ),
  );

  switch (layout.type) {
    case 'full-bg':
      return {
        version: 'v1',
        start: 1,
        end: gridSize,
      };

    case 'left':
    case 'top':
    case 'side-by-side':
      return {
        version: 'v1',
        start: 1,
        end: approxSizeInGridUnits,
      };

    case 'right':
    case 'bottom':
    case 'side-by-side-right':
      return {
        version: 'v1',
        start: gridSize - approxSizeInGridUnits + 1,
        end: gridSize,
      };

    default: {
      return {
        version: 'v1',
        start: 1,
        end: approxSizeInGridUnits,
      };
    }
  }
};

export const questionLayoutToSlideLayout = (
  legacyLayout: Layout | undefined,
): [
  layout: LayoutSchema,
  layoutPosition: LayoutPositionSchema,
  inset: boolean,
] => {
  const layout: LayoutSchema = {
    version: 'v1',
    gridColumns: 8,
    gridRows: 4,
    snapDirection:
      legacyLayoutTypeToDirectionMap[legacyLayout?.type ?? 'default'],
  };

  let layoutPosition: LayoutPositionSchema;

  if (legacyLayout?.type === 'default') {
    layoutPosition = {
      version: 'v1',
      start: 0,
      end: 0,
    };
  } else if (legacyLayout) {
    layoutPosition = convertLegacySizeToGridSpan(
      getGridSize(layout),
      legacyLayout,
    );
  } else {
    layoutPosition = {
      version: 'v1',
      start: 1,
      end: getGridSize(layout),
    };
  }

  return [
    layout,
    layoutPosition,
    legacyLayout?.type?.match(/side-by-side/) !== null,
  ];
};
