import type { Question } from '@mentimeter/http-clients';
import {
  CORRECT_ANSWER_MODE_DISABLED,
  CORRECT_ANSWER_MODE_ENABLED,
  type CorrectAnswerMode,
} from '../../../constants';

export const questionShowCorrectToSlideCorrectAnswerMode = (
  showCorrect: Question['show_correct'],
): CorrectAnswerMode => {
  return showCorrect
    ? CORRECT_ANSWER_MODE_ENABLED
    : CORRECT_ANSWER_MODE_DISABLED;
};
