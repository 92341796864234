import type { Question } from '@mentimeter/http-clients';
import type { RatingInteractiveContentStylingSchema as Styling } from '@mentimeter/schema/interactive-content-styling';
import type { Nullable } from '@mentimeter/ts-utils';

type AxisLabels = Nullable<Pick<Styling, 'xAxisLabels' | 'yAxisLabels'>>;

export const ratingAxisLabelsToQuestionDimensions = ({
  xAxisLabels,
  yAxisLabels,
}: AxisLabels): Question['dimensions'] => {
  return [
    {
      label: xAxisLabels?.axisLabel ?? '',
      min: xAxisLabels?.minimumValueLabel ?? '',
      max: xAxisLabels?.maximumValueLabel ?? '',
    },
    {
      label: yAxisLabels?.axisLabel ?? '',
      min: yAxisLabels?.minimumValueLabel ?? '',
      max: yAxisLabels?.maximumValueLabel ?? '',
    },
  ];
};
