import type { InteractiveContentWithStyling } from '@mentimeter/editor-schema/api-types-overrides';
import type { Question } from '@mentimeter/http-clients';

export const ratingGridToQuestionGrid = (
  interactiveContent: InteractiveContentWithStyling<'rating'>,
): Question['grid'] => {
  if (interactiveContent.styling?.gridImagePath && interactiveContent.image) {
    return {
      id: interactiveContent.interactiveContentId,
      type: 'custom',
      cropped_image_url: interactiveContent.image.presets.original.url,
      image_alt_description: interactiveContent.image.altText ?? '',
      url: interactiveContent.image.presets.source.url,
    } as Question['grid'];
  } else if (interactiveContent.styling?.gridImageKey) {
    return {
      id: interactiveContent.styling.gridImageKey,
      type: 'default',
      rotation: interactiveContent.styling.gridImageRotation ?? 0,
    } as Question['grid'];
  }

  // Something is wrong. Default to default grid
  return {
    id: '2x2',
    type: 'default',
    rotation: 0,
  } as Question['grid'];
};
